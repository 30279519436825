import axios, { AxiosError } from 'axios';

import ForceStopExecutionError from '@/errors/ForceStopExecutionError';
import getRoute                from '@/utils/routing';

const api = axios;

export const errorHandler = error => {
    const response = (error as AxiosError).response;
    const requestConfig = (error as AxiosError).config;
    const requestUri = requestConfig?.url ?? '';

    // We exclude the `/account/customer` and `/account/addresses` endpoints because it's expected that they can respond with HTTP 401
    if (response && response.status === 401 && !requestUri.endsWith('/account/customer') && !requestUri.endsWith('/account/addresses')) {
        // HTTP 401 means that the user has been logged out / session expired; redirect them to login page
        window.location.assign(getRoute('account_index'));

        // We need to throw this error so that the handling is not propagated by Axios, and we can filter it out in
        // external `try..catch()` statements.
        throw new ForceStopExecutionError();
    }

    return Promise.reject(error);
};

api.interceptors.response.use(undefined, errorHandler);

export { type AxiosResponse } from 'axios';

export default api;
